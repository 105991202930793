.App {
  min-height: 100vh;
}

#page-container {
    position: relative;
    min-height: 100vh;
}

#content-wrap {
    padding-bottom: 2.5rem;
}

.App-link {
  color: #61dafb;
}
